import { firstValueFrom } from "rxjs";
import { corppassLogin as getCorppassLoginURL } from '@ntprsrf-frontend/service/src/services/intranet/auth.service';
import type { Router } from "vue-router";

export const fetchCorppassUrl = async () => {
    const res = await firstValueFrom(getCorppassLoginURL())
    
    return res;
}

export const corppassLogin = async (pathToSave: string) => {
    try {
        localStorage.setItem("redirect", JSON.stringify({
            path: pathToSave || '/' // in-case the path is accidentally an empty string 
        }));
    } catch(e) {
        console.error("ERROR HERE", pathToSave);
    }

    const res = await fetchCorppassUrl();
    window.open(
        res,
        "_self",
        "noopener,noreferrer"
    )
}

export const redirectProcess = (router: Router) => {
    const redirectString = localStorage.getItem("redirect")
    if(redirectString) {
        try {
            const redirectPage = JSON.parse(redirectString)
            const r = /^(?:[a-z+]+:)?\/\//i;
            if (r.test(redirectPage.path)) { // if true then it is an absolute path
                window.location.href = redirectPage.path;
            } else {
                router.push(redirectPage);
            }
        } catch { // catch if string is not a JSON
            router.push({
                name: "HOME"
            })
        } finally {
            localStorage.removeItem("redirect")
        }
    } else {
        router.push({
            name: "HOME"
        })
    }
}